export default {
  namespaced: true,
  state: {
    isVisibleModal: false,
    pspFeeConfigLogData: {},
  },
  mutations: {
    setVisiblePspFeeConfigLogModal: (state, { isVisible, data }) => {
      state.pspFeeConfigLogData = isVisible ? data : {};
      state.isVisibleModal = isVisible;
    },
  },
  actions: {
    visiblePspFeeConfigLogModal({ commit }, { isVisible, data }) {
      commit('setVisiblePspFeeConfigLogModal', { isVisible, data });
    },
  },
};
