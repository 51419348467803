import { api } from '@/services/http';
import { omit } from 'lodash';
import fileDownload from 'js-file-download';

const API_URL = '/dashboard';

export const DashboardApi = {
  getQuickBooksTransactionByBank: async (params) => {
    const response = await api.request({
      url: `${API_URL}/quickBooksTransactionByBank`,
      method: 'get',
      params,
    });

    return {
      tableData: response.data.data,
      tableTotalColumnData: response.data.total,
      tableTotalCount: response.count,
      tableIsInvalid: response.data.isInvalid,
    };
  },
  getFeeStatistics: async (params) => {
    const response = await api.request({
      url: `${API_URL}/feeStatistics`,
      method: 'get',
      params: omit(params, ['limit', 'offset']),
    });

    return {
      tableData: response.data.data,
      tableTotalColumnData: response.data.total,
    };
  },
  getPspAccountBalancePeriodList: async (params) => {
    const { data } = await api.request({
      url: '/dashboard/pspAccountBalancePeriod',
      method: 'get',
      params,
    });

    const processItem = (item) => {
      const newItem = { ...item };

      if (newItem.balanceData) {
        Object.keys(newItem.balanceData).forEach((key) => {
          newItem[key] = newItem.balanceData[key];
        });
        delete newItem.balanceData;
      }

      if (newItem.groupedBySlug && newItem.groupedBySlug.length > 0) {
        newItem.groupedBySlug = newItem.groupedBySlug.map(processItem);
      }

      return newItem;
    };

    const processedTableData = data.tableData.map(processItem);

    // Удаление параметра children из статичных колонок
    const processedColumnsData = data.tableColumns.map((item) => {
      const newItem = { ...item };

      const staticColumns = [
        'pspSlug',
        'provider',
        'pspAccountList',
        'brandList',
        'currency',
      ];

      if (staticColumns.includes(newItem.prop)) {
        delete newItem.children;
      }

      return newItem;
    });

    return {
      ...data,
      tableColumns: processedColumnsData,
      tableData: processedTableData,
    };
  },
  getPspAccountBalanceDayList: async (params) => {
    const { data } = await api.request({
      url: '/dashboard/pspAccountBalanceDay',
      method: 'get',
      params,
    });

    const processItem = (item) => {
      const newItem = { ...item };

      if (newItem.balanceData) {
        Object.keys(newItem.balanceData).forEach((key) => {
          newItem[key] = newItem.balanceData[key];
        });
        delete newItem.balanceData;
      }

      if (newItem.groupedBySlug && newItem.groupedBySlug.length > 0) {
        newItem.groupedBySlug = newItem.groupedBySlug.map(processItem);
      }

      return newItem;
    };

    const processedTableData = data.tableData.map(processItem);

    // Удаление параметра children из статичных колонок
    const processedColumnsData = data.tableColumns.map((item) => {
      const newItem = { ...item };

      const staticColumns = [
        'pspSlug',
        'provider',
        'pspAccountList',
        'brandList',
        'currency',
      ];

      if (staticColumns.includes(newItem.prop)) {
        delete newItem.children;
      }

      return newItem;
    });

    return {
      ...data,
      tableColumns: processedColumnsData,
      tableData: processedTableData,
    };
  },
  getPspAccountBalanceReportList: async (params) => {
    const { data, count } = await api.request({
      url: '/finance/pspAccountBalanceReport',
      method: 'get',
      params: {
        limit: 1000,
        offset: 0,
        ...params,
      },
    });

    return { data, count };
  },
  downloadPspAccountBalanceReport: async (id) => {
    const file = await api.request({
      url: `/finance/pspAccountBalanceReport/${id}`,
      responseType: 'blob',
      headers: {
        Accept:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      method: 'get',
    });

    fileDownload(file, 'psp_account_balance_report.csv');
  },
  createPspAccountBalanceReport: async (data) => {
    await api.request({
      url: '/finance/pspAccountBalanceReport',
      method: 'post',
      data,
    });
  },
};
